import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Hero from "../components/hero"
import PageContent from "../components/pageContent"

import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

class Index extends React.Component {
  render() {
    const hero = this.props.data.allContentfulHero.edges[0].node
    const info = documentToReactComponents(hero.childContentfulHeroInfoRichTextNode.json)
    const content = documentToReactComponents(this.props.data.allContentfulPage.edges[0].node.content.json)

    return (
      <Layout>    
        <Hero title={hero.title} subtitle={hero.subtitle} right={info}/>
        <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />    
        <PageContent content={content}></PageContent>
      </Layout>
    )
  }
}

export default Index

export const query = graphql`
{
  allContentfulHero {
    edges {
      node {
        title
        subtitle
        childContentfulHeroInfoRichTextNode {
          json
        }
      }
    }
  }
  allContentfulPage(filter: {title: {eq: "Impressum"}}) {
    edges {
      node {
        title
        content {
          json
        }
      }
    }
  }
}

`